export const useFilesStore = defineStore("useFilesStore", () => {
	const files = useState<IDocFile[]>("pinia:filesStorefiles", () => []);
	const i = useState<number>("pinia:fileIndex", () => 0);
	const currentFile = useState<IDocFile | undefined>(
		"pinia:currFile",
		() => undefined
	);
	const payload = ref({ tenantKey: "", dboKey: "", stage: "", status: "" });
	const stage = ref("");
	const status = ref("");
	const seachText = ref("");
	const selectedStage = ref("all");
	const workingFiles = ref<IDocFile[]>([])
	const started = ref(false)

  const nextDoc = ()=> {
		const docsArray = Array.from(files.value) 
		if(i.value >= docsArray.length) i.value = 0  
		const doc =  docsArray[i.value]
		i.value++
		currentFile.value = doc
		return doc
	
	}
	const setCurrentFile = (file: IDocFile) => {
		currentFile.value = file;
	};
	const setCurrentFileById = (id: string) => {
		currentFile.value = files.value.find((f) => f.id === id);
	};
	const open = computed(() => {
		const regex = new RegExp(seachText.value, "i");
		const stage = selectedStage.value;
		if(!files.value.filter) return []
		console.log("useFilesStore.files.value",files.value)
		return files.value
			.filter((x: any) =>
				[
					"open",
					"assigned",
					"validating",
					"verified",
					"detailing",
					"posted",
				].includes(x.status)
			)
			.filter((x: any) => !(x.stage === "open" && x.status === "open"))
			.filter((x: any) => regex.test(x.name))
			.filter((x: any) => stage === "all" || ['open', 'processing'].includes(x.stage));
	});
	const queries = computed(() => {
		if(!files.value.filter) return []
		return files.value.filter((x: any) => x.stage === "query");
	});
	const closed = computed(() => {
		if(!files.value.filter) return []
		return files.value.filter((x: any) => ['reviewing', 'done'].includes( x?.stage ));
	});

	const getDboFiles = async (
		tenantKey: string,
		dboKey: string,
		stage: IDocStage,
		status: string
	) => {
		payload.value = {
			tenantKey: tenantKey,
			dboKey: dboKey,
			stage: stage,
			status: status,
		};
		 console.log("Payload", payload.value);
		const results = await $fetch<IDocFile[]>("/api/docs/dbofiles", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(payload.value),
		});
		files.value = results;
		return files;
	};
/**
 * Next file to be processed. 
 * @returns a file object or undefined if the list is empty
 */
const nextFile = async ()=>{
	
	if(workingFiles.value.length == 0 && !started.value){
		const newfiles = files.value.filter((x: any) => (["open","processing"].includes(x.stage) 
		&& ["open","validating","verified",].includes( x.status)
		&& x.id != currentFile.value?.id))
		workingFiles.value = newfiles
	}	

	if(workingFiles.value.length > 0){
		const item = workingFiles.value[0]
		workingFiles.value.splice(0,1)
		return item

	}
	return undefined
}
	return {
		files, 
		currentFile,
		nextDoc,
		getDboFiles,
		setCurrentFile,
		nextFile,
		setCurrentFileById,
    open, queries, closed, stage, status, seachText, selectedStage,

	};
}, {persist: {storage: persistedState.localStorage}});
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useFilesStore, import.meta.hot));
}
